import React from "react"
import { Col, Form, Input, Row } from "antd"
import { useTranslation } from "react-i18next"

const SignUpStepCompileUserProfileForm = ({
  initialValues,
  onFinish,
  formRef,
}) => {
  const { t } = useTranslation()

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      className="profile-form"
    >
      <Row justify="center">
        <Col>
          <div className="sign-up-step-header">
            <h1>{t("form:signUpStep.compileProfile.title")}</h1>
            <p>{t("form:signUpStep.compileProfile.description")}</p>
          </div>
        </Col>
      </Row>

      <Form.Item
        label={t("form:label.firstname")}
        name="firstname"
        rules={[
          {
            required: true,
            message: t("form:error.firstnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.firstname")} size="small" />
      </Form.Item>

      <Form.Item
        label={t("form:label.lastname")}
        name="lastname"
        rules={[
          {
            required: true,
            message: t("form:error.lastnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.lastname")} size="small" />
      </Form.Item>

      <Form.Item
        label={t("form:label.phoneNumber")}
        name="phone_number"
        rules={[
          {
            required: true,
            message: t("form:error.phoneNumberRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.phoneNumber")} size="small" />
      </Form.Item>

      <Form.Item
        label={t("form:label.email")}
        name="email"
        rules={[
          {
            required: true,
            message: t("form:error.emailRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.email")} size="small" />
      </Form.Item>
    </Form>
  )
}

export default SignUpStepCompileUserProfileForm
